import request from '../utils/request';

/**
 * 小程序菜单列表
 * @param query
 * @returns {AxiosPromise}
 */
export const list = (query) => {
    return request({
        url: '/sys/menus/list',
        method: 'get',
        params:query
    });
};
/**
 * 小程序修改菜单
 * @param menu
 * @returns {AxiosPromise}
 */
export const edit = (menu) => {
    return request({
        url: '/sys/menus/edit',
        method: 'post',
        data:menu
    });
};
/**
 * 小程序新增菜单
 * @param menu
 * @returns {AxiosPromise}
 */
export const addMenu = (menu) => {
    return request({
        url: '/sys/menus/add',
        method: 'post',
        data:menu
    });
};
/**
 * 小程序删除菜单
 * @param id
 * @returns {AxiosPromise}
 */
export const delMenu = (id) => {
    return request({
        url: '/sys/menus/del',
        method: 'get',
        params:{id}
    });
};



/**
 * web菜单列表
 * @param id
 * @returns {AxiosPromise}
 */
export const getSysMenu = () => {
    return request({
        url: '/sys/menu/list',
        method: 'get',
    });
};
/**
 * web菜单新增
 * @returns {AxiosPromise}
 */
export const add = (menu) => {
    return request({
        url: '/sys/menu/add',
        method: 'post',
        data: JSON.stringify(menu),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};