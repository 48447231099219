<template>
  <div style="padding: 20px">

    <div>
      <el-input v-model="query.name" placeholder="菜单名称" style="width: 160px" class="mr10"></el-input>
      <el-button type="primary" class="handle-del mr10" @click="addClick('PMenu')">新增</el-button>
    </div>
    <div>
      <el-table :data="menu" style="margin-top: 20px" height="700px" row-key="id"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column type="index" label="序号" width="100" align="center"></el-table-column>
        <el-table-column prop="title" label="菜单名称"/>
        <el-table-column label="菜单图标">
          <template #default="scope">
            <span style="font-size: 20px" :class="scope.row.icon"></span>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序"/>
        <el-table-column prop="sort" label="子类数量">
          <template #default="scope">
            <span>{{ scope.row.children.length }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="状态">
          <template #default="scope">
            <el-tooltip :content="scope.row.status=='开启'? '关闭后此教练将无法使用':'确定要开启吗'" placement="top">
              <el-switch
                  v-model="scope.row.status"
                  :active-value="0"
                  :inactive-value="1"
              >
              </el-switch>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template #default="scope">
            <!--                        <el-button type="text" size="small">编辑</el-button>-->
            <el-button type="text" size="small" @click="addClick(scope.row)">新增</el-button>
            <!--                        <el-button type="text" size="small">删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="菜单录入" v-loading="addLogin" v-model="addVisible" width="900px">
      <el-form v-loading="addLogin" ref="addRules" :rules="addRules" :model="addItem"
               style="padding: 20px 40px 20px 20px" label-width="90px">
        <el-row>
          <el-col :span="8">
            <el-form-item prop="coachName" label="菜单名称">
              <el-input v-model="addItem.title"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="coachName" label="菜单图标">
              <el-input v-model="addItem.icon"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item prop="coachName" label="路由">
              <el-input v-model="addItem.index"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="coachName" label="排序">
              <el-input type="number" v-model="addItem.sort"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
                prop="coachName" label="是否是按钮">
              <el-switch
                  v-model="addItem.isButton"
                  :active-value="1"
                  :inactive-value="0"
              >
              </el-switch>
            </el-form-item>

          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button :disabled="addLogin" @click="addVisible = false">取 消</el-button>
          <el-button :disabled="addLogin" type="primary"
                     @click="saveAdd()">{{ addLogin == true ? '请稍等..' : '确 定' }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {getSysMenu, add} from "../../api/menu";

export default {
  name: "SYSMenu",
  data() {
    return {
      addVisible: false,
      query: {
        name: ""
      },
      addItem: {pid: null, title: null, index: null, icon: null, sort: null, isButton: null,},
      menu: []
    }
  },
  created() {
    getSysMenu().then(value => {
      this.menu = value.data;
      // console.log(value)
    })
  }, methods: {
    addClick(val) {
      // console.log(val);
      if (val !== "PMenu") {
        this.addItem.pid = val.id;
      } else {
        this.addItem.pid = null;
      }
      this.addVisible = true;
    },
    saveAdd() {
      console.info(this.addItem);

      this.$refs['addRules'].validate((valid) => {
        if (valid) {
          add(this.addItem).then(res => {
            this.addVisible = false;
            if (res.code === 200) {
              this.$message.success(`新增成功`);
              this.getData();
            } else {
              this.$message.error(`新增失败` + res.message);
            }
            this.addItem = {}
          })
        } else {
          return false;
        }
      })
    },
  }
}
</script>

<style scoped>

</style>